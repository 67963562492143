import { ROLES } from '@/shared/constants';

const roles = [
  ROLES.ROLE_OPERATOR,
  ROLES.ROLE_AGENT,
  ROLES.ROLE_SUPERVISOR,
  ROLES.ROLE_ADMIN,
  ROLES.ROLE_SUPPORT_OFFICER,
];

export default {
  path: '/payout-orders',
  name: 'Payout Orders',
  redirect: {
    name: 'PayoutOrdersList',
  },
  meta: {
    icon: 'mdi-application',
    roles,
  },
  component: {
    name: 'PayoutOrders',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'PayoutOrdersList',
      meta: {
        icon: 'mdi-application',
        title: 'Payout orders list',
        roles,
      },
      component: () => import('./list'),
    },
    {
      path: 'management',
      name: 'PayoutManagement',
      meta: {
        icon: 'mdi-application',
        title: 'Payout Management',
        roles: [
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_SUPERVISOR,
          ROLES.ROLE_OPERATOR,
          ROLES.ROLE_AGENT,
        ],
      },
      component: () => import('./management'),
    },
  ],
};
