import { default as account } from '@/pages/account';
import { default as balance } from '@/pages/balance';
import { default as login } from '@/pages/login';
import { default as merchants } from '@/pages/merchants';
import { default as paymentRequisites } from '@/pages/payment-requisites';
import { default as rules } from '@/pages/payout-assignment-rules';
import { default as payoutMethods } from '@/pages/payout-methods';
import { default as payoutOrders } from '@/pages/payout-orders';
import { default as refillMethods } from '@/pages/refill-methods';
import { default as refillOrders } from '@/pages/refill-orders';
import { default as users } from '@/pages/users';
import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export const routes = [
  {
    path: '/',
    redirect: () => {
      const isAdmin = authUtils.checkRoles([ROLES.ROLE_ADMIN]);
      const isAgent = authUtils.checkRoles([ROLES.ROLE_AGENT]);
      const isAdminFilter = authUtils.checkRoles([ROLES.ROLE_ADMIN_FILTER]);

      if (isAdmin) return '/merchants';
      if (isAgent || isAdminFilter) return '/payment-requisites';
      return '/refill-orders';
    },
    meta: {
      excludeFromNavBar: true,
    },
  },
  login,
  merchants,
  refillMethods,
  payoutMethods,
  users,
  refillOrders,
  paymentRequisites,
  payoutOrders,
  account,
  balance,
  rules,
];
