export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_OPERATOR: 'ROLE_OPERATOR',
  ROLE_AGENT: 'ROLE_AGENT',
  ROLE_ADMIN_FILTER: 'ROLE_ADMIN_FILTER',
  ROLE_SUPERVISOR: 'ROLE_SUPERVISOR',
  ROLE_SUPPORT_OFFICER: 'ROLE_SUPPORT_OFFICER',
};

export const APP_NAME = 'BirPay Gate';

export const STATUSES = {
  PENDING: 0,
  APPROVED: 1,
  DECLINED: 2,
  CANCELED: 3,
};

export const ORDER_STATUS_DICTIONARY = [
  {
    value: STATUSES.PENDING,
    label: 'Pending',
    color: 'grey-5',
  },
  {
    value: STATUSES.CANCELED,
    label: 'Canceled',
    color: 'yellow-5',
  },
  {
    value: STATUSES.APPROVED,
    label: 'Approved',
    color: 'green-2',
  },
  {
    value: STATUSES.DECLINED,
    label: 'Declined',
    color: 'red-2',
  },
];

export const defaultStatusesPack = ORDER_STATUS_DICTIONARY.filter(
  (el) => el.value === STATUSES.APPROVED || el.value === STATUSES.DECLINED
);

const approveStatusPack = [ORDER_STATUS_DICTIONARY[2]];

export const ORDER_TYPES = {
  REFILLS: 'refills',
  PAYOUTS: 'payouts',
};

export const ORDER_STATUS_DISABLING_MAP = {
  [ORDER_TYPES.REFILLS]: {
    [ROLES.ROLE_ADMIN]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: false,
    },
    [ROLES.ROLE_AGENT]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: false,
    },
    [ROLES.ROLE_SUPERVISOR]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: false,
    },
    [ROLES.ROLE_OPERATOR]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: true,
    },
  },
  [ORDER_TYPES.PAYOUTS]: {
    [ROLES.ROLE_ADMIN]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: false,
      [STATUSES.CANCELED]: false,
    },
    [ROLES.ROLE_AGENT]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: true,
      [STATUSES.CANCELED]: true,
    },
    [ROLES.ROLE_SUPERVISOR]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: false,
      [STATUSES.CANCELED]: false,
    },
    [ROLES.ROLE_OPERATOR]: {
      [STATUSES.PENDING]: false,
      [STATUSES.APPROVED]: true,
      [STATUSES.DECLINED]: true,
      [STATUSES.CANCELED]: true,
    },
  },
};


export const ORDER_STATUS_DICTIONARIES_MAP = {
  [ORDER_TYPES.REFILLS]: {
    [ROLES.ROLE_ADMIN]: {
      [STATUSES.PENDING]: approveStatusPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: approveStatusPack,
    },
    [ROLES.ROLE_AGENT]: {
      [STATUSES.PENDING]: approveStatusPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: [],
    },
    [ROLES.ROLE_SUPERVISOR]: {
      [STATUSES.PENDING]: approveStatusPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: approveStatusPack,
    },
    [ROLES.ROLE_OPERATOR]: {
      [STATUSES.PENDING]: approveStatusPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: [],
    },
  },
  [ORDER_TYPES.PAYOUTS]: {
    [ROLES.ROLE_ADMIN]: {
      [STATUSES.PENDING]: defaultStatusesPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: approveStatusPack,
      [STATUSES.CANCELED]: approveStatusPack,
    },
    [ROLES.ROLE_AGENT]: {
      [STATUSES.PENDING]: defaultStatusesPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: [],
      [STATUSES.CANCELED]: [],
    },
    [ROLES.ROLE_SUPERVISOR]: {
      [STATUSES.PENDING]: defaultStatusesPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: approveStatusPack,
      [STATUSES.CANCELED]: approveStatusPack,
    },
    [ROLES.ROLE_OPERATOR]: {
      [STATUSES.PENDING]: defaultStatusesPack,
      [STATUSES.APPROVED]: [],
      [STATUSES.DECLINED]: [],
      [STATUSES.CANCELED]: [],
    },
  },
};

export const MASKS = {
  Q_DATE: 'YYYY-MM-DD',
  DATETIME: 'DD.MM.YYYY HH:mm:ss',
  DATE: 'DD.MM.YYYY',
  TIME: 'HH:mm:ss',
};

export const MESSAGES_401 = {
  MISSING_OTP: 'missing_otp_exception',
  TIMEOUT: 'timeout_exception',
  INVALID_OTP: 'invalid_otp_exception',
  ANOTHER_DEVICE: 'You are logged in from another device',
};

export const CURRENCY_DICTIONARY = ['USD'];

export const LOGOUT_TIME_LIMIT = 62 * 60 * 1000; // 62 min
export const NOTIFICATION_TIME = 2 * 60 * 1000; // 2 min
