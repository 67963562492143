<template>
  <component :is="root.is" v-bind="root">
    <q-chip
      :dense="isDense"
      :icon-right="isRight ? 'mdi-account-circle' : undefined"
      :icon="isRight ? undefined : 'mdi-account-circle'"
      clickable
      size="md"
      class="bg-white shadow-2"
    >
      <span class="q-px-sm"> {{ label || username }} </span>
      <q-badge
        v-if="isWithBadge"
        :color="status ? 'green' : 'red'"
        rounded
        floating
        class="shadow-2"
      />
      <q-menu
        v-if="isWithMenu"
        fit
        :offset="[11, 11]"
        content-class="bg-grey-1"
      >
        <div class="row no-wrap q-pa-md">
          <div class="column full-width items-center">
            <q-avatar color="grey-3" size="72px" icon="mdi-account" />

            <div class="text-subtitle1 q-mt-md q-mb-xs">{{ username }}</div>

            <q-btn
              color="primary"
              label="Logout"
              push
              size="sm"
              @click="logout"
              v-close-popup
            />
          </div>
          <q-separator vertical inset class="q-mx-lg" />
          <div class="column">
            <div class="text-h6 q-mb-md">Roles</div>
            <template v-for="role in userRoles">
              <q-chip :key="role"> {{ role }} </q-chip>
            </template>
          </div>
        </div>
      </q-menu>
    </q-chip>
  </component>
</template>

<script>
import withLogOut from '@/shared/mixins/withLogOut';
import withUserData from '@/shared/mixins/withUserData';

export default {
  mixins: [withUserData, withLogOut],
  props: {
    label: {
      type: String,
      default: () => '',
    },
    isWithMenu: {
      type: Boolean,
      default: () => false,
    },
    isDense: {
      type: Boolean,
      default: () => false,
    },
    status: {
      type: Boolean,
      default: () => false,
    },
    isWithBadge: {
      type: Boolean,
      default: () => false,
    },
    to: {
      type: [Object, String],
      default: () => undefined,
    },
    isRight: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    root: ({ to }) =>
      to
        ? {
            is: 'router-link',
            to,
          }
        : {
            is: 'fragment',
          },
  },
};
</script>

<style lang="scss" scoped>
/deep/.q-badge {
  height: 12px;
}

a:-webkit-any-link {
  color: transparent;
  text-decoration: none;
}
</style>
