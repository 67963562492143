import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      userData: 'GET_USER_DATA',
      userRoles: 'GET_USER_ROLES',
    }),
    username: ({ userData }) => userData?.data?.username,
  },
};
