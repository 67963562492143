import axios from 'axios';

const BASE_PATH = '/api/ui/payout_management';

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @param {Object} payload
 * @param {Number} payload.payoutMethodTypeId,
 * @param {Number} payload.agent,
 * @param {String} payload.status,
 */
const getPayouts = (payload) => axios.post(`${BASE_PATH}/find`, payload);

export default {
  getPayouts,
};
