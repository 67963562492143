import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/payout_assignment',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/payout_assignment',
};

export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    /**
     * @description - Получение списка правил
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {Number} payload.filter.status - статус
     * @param {Number} payload.filter.agentId - ид агента
     * @param {Number} payload.filter.payoutMethodTypeId - ид платежного метода
     * @param {Object} [payload.limit] - фильтры лимитов
     * @param {Number} payload.limit.lastId
     * @param {Number} payload.limit.maxResults
     * @param {Boolean} payload.limit.descending
     * @returns {Promise<AxiosResponse<any>>}
     */
    getRules: (payload) => axios.post(`${CONTROLLER_BASE_PATH}/find`, payload),

    /**
     * @description - Создание правила
     * @param {Object} payload
     * @param {String} payload.name - наименование правила
     * @param {Object} payload.payoutMethodType - платежный метод
     * @param {Number} payload.payoutMethodType.id - платежный метод
     * @param {Array} payload.rules - праивла
     * @param {Number} payload.status - праивла
     * @returns {Promise<AxiosResponse<any>>}
     */
    createRule: (payload) => axios.post(`${CONTROLLER_BASE_PATH}`, payload),

    /**
     * @description - Обновление правила
     * @param {Object} payload
     * @param {Number} payload.id - id
     * @param {String} payload.name - наименование правила
     * @param {Object} payload.payoutMethodType - платежный метод
     * @param {Number} payload.payoutMethodType.id - платежный метод
     * @param {Array} payload.rules - праивла
     * @param {Number} payload.status - праивла
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateRule: (payload) => axios.put(`${CONTROLLER_BASE_PATH}`, payload),

    findRuleById: (id) => axios.get(`${CONTROLLER_BASE_PATH}/${id}`),
  };
};
