import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_OPERATOR]: '/api/operator/refill_order',
  [ROLES.ROLE_AGENT]: '/api/agent/refill_order',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/refill_order',
  [ROLES.ROLE_ADMIN]: '/api/admin/refill_order',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/refill_order',
};

/**
 * @param role
 * @returns {{approveOrder: (function(*): Promise<AxiosResponse<any>>), getOrders: (function({filter?: {status: Number, merchantId: Number, paymentRequisiteId: Number, merchantTransactionId: Number, currency: String, customerTransactionId: Number, customerWalletId: Number, customerName: String}, limit?: {lastId: Number, maxResults: Number, descending: Boolean}}): Promise<AxiosResponse<*>>), declineOrder: (function(*): Promise<AxiosResponse<any>>), changeOrder: (function({id: Number, amount?: String, paymentRequisiteId?: Number}): Promise<AxiosResponse<*>>)}}
 */
export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    /**
     * @description - Получение заказов на пополнение
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {Number} payload.filter.status - статус
     * @param {Number} payload.filter.merchantId - ид мерчанта
     * @param {Number} payload.filter.paymentRequisiteId
     * @param {Number} payload.filter.merchantTransactionId
     * @param {String} payload.filter.currency
     * @param {Number} payload.filter.customerTransactionId
     * @param {Number} payload.filter.customerWalletId
     * @param {String} payload.filter.customerName
     * @param {Object} [payload.limit] - фильтры лимитов
     * @param {Number} payload.limit.lastId
     * @param {Number} payload.limit.maxResults
     * @param {Boolean} payload.limit.descending
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOrders: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/find`, {
        ...payload,
      }),
    declineOrder: (id) =>
      axios.put(`${CONTROLLER_BASE_PATH}/decline`, {
        id,
      }),
    approveOrder: (id) =>
      axios.put(`${CONTROLLER_BASE_PATH}/approve`, {
        id,
      }),
    /**
     * @param {Object} payload
     * @param {Number} payload.id - id заказа
     * @param {String} [payload.amount] - сумма
     * @param {Number} [payload.paymentRequisiteId] - id платежного реквизита
     * @returns {Promise<AxiosResponse<any>>}
     */
    changeOrder: (payload) =>
      axios.put(`${CONTROLLER_BASE_PATH}/change`, {
        ...payload,
      }),

    /**
     * @description - Подтверждение отчета
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {Number} payload.filter.status - статус
     * @param {Number} payload.filter.merchantId - ид мерчанта
     * @param {Number} payload.filter.paymentRequisiteId
     * @param {Number} payload.filter.merchantTransactionId
     * @param {String} payload.filter.currency
     * @param {Number} payload.filter.customerTransactionId
     * @param {Number} payload.filter.customerWalletId
     * @param {String} payload.filter.customerName
     * @param {String} payload.email - почта
     * @returns {Promise<AxiosResponse<any>>}
     */
    applyReport: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/report`, {
        ...payload,
      }),

    changeOrderAmount: ({ id, amount }) =>
      axios.put(`${CONTROLLER_BASE_PATH}/change/amount`, {
        id,
        amount,
      }),

    changeOrderCustomerTransactionId: ({ id, customerTransactionId }) =>
      axios.put(`${CONTROLLER_BASE_PATH}/change/customer_transaction_id`, {
        id,
        customerTransactionId,
      }),

    changeOrderRequisite: ({ id, paymentRequisiteId }) =>
      axios.put(`${CONTROLLER_BASE_PATH}/change/requisite`, {
        id,
        paymentRequisiteId,
      }),
  };
};
