import { ROLES } from '@/shared/constants';
import {ComputedPermissions} from "@/shared/utils/permissionsHelper";

const extraPermission = (userData) =>
    ComputedPermissions.canShowRequisite(userData.data.permissions);

export default {
  path: '/payment-requisites',
  name: 'PaymentRequisites',
  redirect: {
    name: 'PaymentRequisitesList',
  },
  meta: {
    icon: 'mdi-credit-card-multiple',
    extraPermission,
    roles: [
      ROLES.ROLE_AGENT,
      ROLES.ROLE_ADMIN_FILTER,
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_SUPERVISOR,
      ROLES.ROLE_SUPPORT_OFFICER,
    ],
  },
  component: {
    name: 'PaymentRequisites',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'PaymentRequisitesList',
      meta: {
        icon: 'mdi-credit-card-multiple',
        title: 'Payment requisites list',
        extraPermission,
        roles: [
          ROLES.ROLE_AGENT,
          ROLES.ROLE_ADMIN_FILTER,
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_SUPERVISOR,
          ROLES.ROLE_SUPPORT_OFFICER,
        ],
      },
      component: () => import('./list'),
    },
    {
      path: 'create',
      name: 'PaymentRequisitesCreate',
      meta: {
        icon: 'mdi-credit-card-multiple',
        title: 'Payment requisites creation',
        roles: [ROLES.ROLE_AGENT, ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_OPERATOR],
      },
      component: () => import('./edit'),
    },
    {
      path: 'edit/:id',
      meta: {
        excludeFromNavBar: true,
      },
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '',
          name: 'PaymentRequisitesEdit',
          meta: {
            extraPermission,
            title: 'Payment requisites editing',
            roles: [
              ROLES.ROLE_AGENT,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPERVISOR,
              ROLES.ROLE_OPERATOR,
            ],
          },
          component: () => import('./edit'),
        },
        {
          path: 'filter-editor',
          name: 'PaymentRequisiteFilterEdit',
          meta: {
            isEdit: true,
            title: 'Payment requisite filters editor',
            roles: [ROLES.ROLE_ADMIN_FILTER],
          },
          component: () => import('./filter-edit'),
        },
        {
          path: 'filter-editor-creation',
          name: 'PaymentRequisiteFilterCreation',
          meta: {
            title: 'Payment requisite filters creation',
            roles: [ROLES.ROLE_ADMIN_FILTER],
          },
          component: () => import('./filter-edit'),
        },
      ],
    },
  ],
};
