import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/refill_method_type',
  [ROLES.ROLE_AGENT]: '/api/agent/refill_method_type',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/refill_method_type',
  [ROLES.ROLE_OPERATOR]: '/api/operator/refill_method_type',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/refill_method_type',
};

export default (role = ROLES.ROLE_ADMIN) => {
  // FIXME @mozalov: на будущее
  //  - кажется не очень безопасным так менеджерить роуты, потому как в role может прийти роль, которой нет в pathMap, нужна явная обработка\ошибка
  //  - обобщить бы апи механики
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    getRefillMethods: () => axios.get(CONTROLLER_BASE_PATH),
    getRefillMethodById: (id) => axios.get(`${CONTROLLER_BASE_PATH}/${id}`),
    updateRefillMethod: (payload) =>
      axios.put(CONTROLLER_BASE_PATH, {
        ...payload,
      }),
    createRefillMethod: (payload) =>
      axios.post(CONTROLLER_BASE_PATH, {
        ...payload,
      }),
    removeRefillMethod: (id) =>
      axios.delete(CONTROLLER_BASE_PATH, {
        data: { id },
      }),
    addUserToRefillMethodType: (payload) =>
      axios.put(`${CONTROLLER_BASE_PATH}/user`, {
        ...payload,
      }),
    removeUserAtRefillMethodType: (payload) =>
      axios.delete(`${CONTROLLER_BASE_PATH}/user`, {
        data: { ...payload },
      }),
    addMerchantToRefillMethodType: (payload) =>
      axios.put(`${CONTROLLER_BASE_PATH}/merchant`, {
        ...payload,
      }),
    removeMerchantToRefillMethodType: (payload) =>
      axios.delete(`${CONTROLLER_BASE_PATH}/merchant`, {
        data: { ...payload },
      }),
  };
};
