import { ROLES } from '@/shared/constants';

export default {
  path: '/balance',
  name: 'Balance',
  redirect: {
    name: 'BalanceList',
  },
  meta: {
    icon: 'mdi-wallet',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_AGENT],
  },
  component: {
    name: 'Balance',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'top-up',
      name: 'TopUpBalance',
      meta: {
        icon: 'mdi-bank',
        title: 'Top up balance',
        roles: [ROLES.ROLE_AGENT],
      },
      component: () => import('./top-up'),
    },
    {
      path: 'list',
      name: 'BalanceList',
      meta: {
        icon: 'mdi-wallet',
        title: 'List of balances',
        roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_AGENT],
      },
      component: () => import('./list'),
    },
    {
      path: 'payment-history',
      name: 'PaymentsHistory',
      meta: {
        icon: 'mdi-history',
        title: 'History of payments',
        roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_AGENT],
      },
      component: () => import('./payment-history'),
    },
    {
      path: 'create-pre-payment',
      name: 'CreatePrePayment',
      meta: {
        icon: 'mdi-wallet-plus',
        title: 'Create pre-payment',
        roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR],
      },
      component: () => import('./edit'),
    },
    {
      path: 'limits-list',
      name: 'LimitsList',
      meta: {
        icon: 'mdi-wallet',
        title: 'Balance limits',
        roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR],
      },
      component: () => import('./limits-list'),
    },
    {
      path: 'limits-edit',
      name: 'LimitsEdit',
      meta: {
        icon: 'mdi-wallet-plus',
        title: 'Balance limits edit',
        roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR],
      },
      component: () => import('./limits-edit'),
    },
  ],
};
