const storageKeys = {
  USER_DATA: 'user_data',
};

const actions = {
  setItem({ key, value }) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getItem(key) {
    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : null;
  },
  removeItem(key) {
    localStorage.removeItem(key);
  },
  clearStorage() {
    localStorage.clear();
  },
};

export default {
  actions,
  storageKeys,
};
