import '@quasar/extras/mdi-v5/mdi-v5.css';
import Dialog from 'quasar/src/plugins/Dialog.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';;
import 'quasar/dist/quasar.ie.polyfills';
import iconSet from 'quasar/icon-set/mdi-v5';
import lang from 'quasar/lang/en-us';

export default {
  config: {
    loadingBar: {
      color: 'blue',
    },
  },
  plugins: {
    Notify,
    Dialog,
    LoadingBar,
  },
  lang,
  iconSet,
};
