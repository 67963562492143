import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_AGENT]: '/api/agent/user',
  [ROLES.ROLE_ADMIN]: '/api/admin/user',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/user',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/user',
};

export default (role = ROLES.ROLE_ADMIN) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    getUsers: () => axios.get(CONTROLLER_BASE_PATH),
    getUserById: (id) => axios.get(`${CONTROLLER_BASE_PATH}/${id}`),
    getOperatorsByAgent: (id) =>
      role === ROLES.ROLE_AGENT
        ? axios.get(`${CONTROLLER_BASE_PATH}/operator-by-agent`)
        : axios.get(`${CONTROLLER_BASE_PATH}/operator-by-agent/${id}`),

    findUsers: (payload) =>
        axios.post(`${CONTROLLER_BASE_PATH}/find`, {
          ...payload,
        }),

    applyReport: (payload) =>
        axios.post(`${CONTROLLER_BASE_PATH}/report`, {
          ...payload,
        }),

    /**
     * @description - Обновление данных пользователя
     * @param {Object} payload
     * @param {Number} payload.id - id пользователя
     * @param {String} [payload.username] - имя пользователя
     * @param {String} [payload.password] - пароль пользователя
     * @param {String[]} [payload.roles] - роли пользователя
     * @param {String} [payload.locale] - локаль пользователя
     * @param {String} [payload.active] - активность учетной записи пользователя
     * @param {String} [payload.countPendingRefill] - false = значит, что в подсчете балансов не учитываются пополнения в статусах "Pending"
     * @param {String} [payload.allowCreateRequisites] - разрешает создавать реквизиты
     * @param {String} [payload.allowSwitchRequisite] - разрешает включать\выключать реквизиты
     * @param {String} [payload.allowEditRequisiteWeight] - разрешает редактировать веса реквизита
     * @param {String} [payload.allowEditRequisitePayload] - разрешает редактировать ключей реквизита
     * @param {String} [payload.allowEditRequisiteRefillMethod] - разрешает редактировать метод депозита
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateUser: (payload) =>
      axios.put(CONTROLLER_BASE_PATH, {
        ...payload,
      }),

    /**
     * @description - Создание пользователя
     * @param {Object} payload
     * @param {String} payload.username - имя пользователя
     * @param {String} payload.password - пароль пользователя
     * @param {String[]} payload.roles - роли пользователя
     * @param {String} payload.locale - локаль пользователя
     * @param {String} payload.active - активность учетной записи пользователя
     * @param {String} [payload.countPendingRefill] - true = значит, что в подсчете балансов не учитываются пополнения в статусах "Pending"
     * @param {String} [payload.allowCreateRequisites] - разрешает создавать реквизиты
     * @param {String} [payload.allowSwitchRequisite] - разрешает включать\выключать реквизиты
     * @param {String} [payload.allowEditRequisiteWeight] - разрешает редактировать веса реквизита
     * @param {String} [payload.allowEditRequisitePayload] - разрешает редактировать ключей реквизита
     * @param {String} [payload.allowEditRequisiteRefillMethod] - разрешает редактировать метод депозита
     * @returns {Promise<AxiosResponse<any>>}
     */
    createUser: (payload) =>
      axios.post(CONTROLLER_BASE_PATH, {
        ...payload,
      }),

    removeUser: (id) =>
      axios.delete(CONTROLLER_BASE_PATH, {
        data: { id },
      }),

    /**
     * @description - Справочник операторов для фильтра
     * @returns {Promise<AxiosResponse<any>>}
     */
    usersForFilter: () => axios.get(`${CONTROLLER_BASE_PATH}/users-for-filter`),

    /**
     * @description - Справочник агентов для фильтра
     * @returns {Promise<AxiosResponse<any>>}
     */
    agentsForFilter: () =>
      axios.get(`${CONTROLLER_BASE_PATH}/agents-for-filter`),

    agentsForPrepayments: () =>
      axios.get(`${CONTROLLER_BASE_PATH}/agents-for-prepayments`),

    agentsForBalance: () =>
      axios.get(`${CONTROLLER_BASE_PATH}/agents-for-balance`),

    agentsForPayoutMethod: (id) =>
      axios.get(`${CONTROLLER_BASE_PATH}/agents-for-payout-method/${id}`),
  };
};
