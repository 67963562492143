export default {
  methods: {
    async logout() {
      await this.$store.dispatch('LOG_OUT');
      await this.$router.replace({
        name: 'Login',
      });
    },
  },
};
