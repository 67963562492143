import axios from 'axios';

const CONTROLLER_BASE_PATH = '/api/admin/merchant';

const getMerchants = () => axios.get(CONTROLLER_BASE_PATH);
const getMerchantById = (id) => axios.get(`${CONTROLLER_BASE_PATH}/${id}`);

/**
 * @description - Обновление сущности merchant
 * @param {Object} payload
 * @param {Number} payload.id - идентификатор мерчанта
 * @param {String} [payload.uid] - uid мерчанта
 * @param {String} [payload.name]
 * @param {String} [payload.secret]
 * @param {String} [payload.refillCallback]
 * @param {String} [payload.payoutCallback]
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateMerchant = (payload) =>
  axios.put(CONTROLLER_BASE_PATH, {
    ...payload,
  });

/**
 * @description - Создание сущности merchant
 * @param {Object} payload
 * @param {String} payload.name
 * @param {String} payload.secret
 * @param {String} payload.refillCallback
 * @param {String} payload.payoutCallback
 * @returns {Promise<AxiosResponse<any>>}
 */
const createMerchant = (payload) =>
  axios.post(CONTROLLER_BASE_PATH, {
    ...payload,
  });

const removeMerchant = (id) =>
  axios.delete(CONTROLLER_BASE_PATH, {
    data: { id },
  });

export default {
  getMerchants,
  getMerchantById,
  updateMerchant,
  createMerchant,
  removeMerchant,
};
