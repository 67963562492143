<template>
  <div class="bg-primary text-white" style="height: 170px">
    <div class="absolute-bottom q-pa-md bg-transparent">
      <q-avatar
        color="grey-3"
        size="56px"
        class="q-mb-sm text-grey"
        icon="mdi-account"
      />
      <div class="text-weight-bold">{{ username }}</div>
      <div class="row">
        <template v-for="role in userRoles">
          <q-chip class="text-caption q-ml-none" dense :key="role">
            {{ role }}
          </q-chip>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import withLogOut from '@/shared/mixins/withLogOut';
import withUserData from '@/shared/mixins/withUserData';

export default {
  mixins: [withUserData, withLogOut],
};
</script>
