import { authUtils, localStorageManager } from '@/shared/utils';
import Vue from 'vue';

const { actions, storageKeys } = localStorageManager;

const checkAccess = (to, from, next) => {
  const isGuest = !!to.meta.guest;
  const isAuthorized = authUtils.isAuth();

  if (!isGuest && isAuthorized) {
    return next();
  }
  if (!isGuest) {
    return next({ name: 'Login' });
  }
  next();
};

const checkRoles = (to, from, next) => {
  const isNeedRoles = to.meta.roles?.length;
  const isHasRoles = authUtils.checkRoles(to.meta.roles);

  if (to.meta?.extraPermission) {
    if (to.meta.extraPermission(actions.getItem(storageKeys.USER_DATA))) {
      return next();
    } else {
      next(from.path);
    }
  }

  if (isNeedRoles && isHasRoles) {
    return next();
  }
  if (isNeedRoles) {
    return next(from.path);
  }

  next();
};

const setPageTitle = (to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Birpay Gate';
  });
};

export default {
  checkAccess,
  checkRoles,
  setPageTitle,
};
