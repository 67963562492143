import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/balance',
  [ROLES.ROLE_AGENT]: '/api/agent/balance',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/balance',
};

export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    async getBalaceTopUpMethods() {
      const { data } = await axios.get(
        `${CONTROLLER_BASE_PATH}/top-up/methods`
      );

      return data;
    },
    async balanceTopUp(payload) {
      const { data } = await axios.post(
        `${CONTROLLER_BASE_PATH}/top-up`,
        payload
      );

      return data;
    },
    getBalances: async (payload) => {
      const response = await axios.post(`${CONTROLLER_BASE_PATH}/find`, {
        ...payload,
      });

      response.data.forEach((item) => {
        if (Number.isFinite(+item.currentBalance)) {
          item.currentBalance = +item.currentBalance;
        }

        if (Number.isFinite(+item.incomingBalance)) {
          item.incomingBalance = +item.incomingBalance;
        }

        if (Number.isFinite(+item.payoutOrderAgentFeeAmount)) {
          item.payoutOrderAgentFeeAmount = +item.payoutOrderAgentFeeAmount;
        }

        if (Number.isFinite(+item.refillOrderAmount)) {
          item.refillOrderAmount = +item.refillOrderAmount;
        }

        if (Number.isFinite(+item.payoutOrderAmount)) {
          item.payoutOrderAmount = +item.payoutOrderAmount;
        }

        if (Number.isFinite(+item.refillOrderAgentFeeAmount)) {
          item.refillOrderAgentFeeAmount = +item.refillOrderAgentFeeAmount;
        }
      });

      return response;
    },

    /**
     * @description - Подтверждение отчета
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {String} payload.filter.agentCurrency
     * @param {String} payload.filter.agentId
     * @param {String} payload.filter.date
     * @param {String} payload.email - почта
     * @returns {Promise<AxiosResponse<any>>}
     */
    applyReport: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/report`, {
        ...payload,
      }),
  };
};
