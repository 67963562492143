import { ROLES } from '@/shared/constants';

const roles = [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR];

export default {
  path: '/users',
  name: 'Users',
  redirect: {
    name: 'UsersList',
  },
  meta: {
    icon: 'mdi-account-group',
    roles,
  },
  component: {
    name: 'Users',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'UsersList',
      meta: {
        title: 'Users list',
        icon: 'mdi-account-group',
        roles,
      },
      component: () => import('./list'),
    },
    {
      path: 'create',
      name: 'UsersCreate',
      meta: {
        title: 'User creation',
        icon: 'mdi-account-multiple-plus',
        roles,
      },
      component: () => import('./edit'),
    },
    {
      path: 'edit/:id',
      name: 'UsersEdit',
      meta: {
        title: 'User editing',
        excludeFromNavBar: true,
        roles,
      },
      component: () => import('./edit'),
    },
  ],
};
