import { ROLES } from '@/shared/constants';

const roles = [
  ROLES.ROLE_OPERATOR,
  ROLES.ROLE_AGENT,
  ROLES.ROLE_SUPERVISOR,
  ROLES.ROLE_ADMIN,
  ROLES.ROLE_SUPPORT_OFFICER,
];

export default {
  path: '/refill-orders',
  name: 'Refill Orders',
  redirect: {
    name: 'RefillOrdersList',
  },
  meta: {
    icon: 'mdi-application',
    roles,
  },
  component: {
    name: 'RefillOrders',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'RefillOrdersList',
      meta: {
        icon: 'mdi-application',
        title: 'Refill orders list',
        roles,
      },
      component: () => import('./list'),
    },
  ],
};
