<template>
  <component :is="layout" />
  <!-- <q-scroll-area style="height: 100vh">
  </q-scroll-area> -->
</template>

<script>
export default {
  name: 'LayoutDefault',
  computed: {
    layout: ({ $route }) => {
      return `${$route.meta.layout || 'common'}-layout`;
    },
  },
};
</script>
