import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/payout_method_type',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/payout_method_type',
  [ROLES.ROLE_AGENT]: '/api/agent/payout_method_type',
  [ROLES.ROLE_OPERATOR]: '/api/operator/payout_method_type',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/payout_method_type',
};

export default (role = ROLES.ROLE_ADMIN) => {
  const CONTROLLER_BASE_PATH = pathMap[role];
  return {
    getPayoutMethods: () => axios.get(CONTROLLER_BASE_PATH),
    getPayoutMethodById: (id) => axios.get(`${CONTROLLER_BASE_PATH}/${id}`),
    updatePayoutMethod: (payload) =>
      axios.put(CONTROLLER_BASE_PATH, {
        ...payload,
      }),
    createPayoutMethod: (payload) =>
      axios.post(CONTROLLER_BASE_PATH, {
        ...payload,
      }),
    removePayoutMethod: (id) =>
      axios.delete(CONTROLLER_BASE_PATH, {
        data: { id },
      }),
    addUserToPayoutMethodType: (payload) =>
      axios.put(`${CONTROLLER_BASE_PATH}/user`, {
        ...payload,
      }),
    removeUserAtPayoutMethodType: (payload) =>
      axios.delete(`${CONTROLLER_BASE_PATH}/user`, {
        data: { ...payload },
      }),
    addMerchantToPayoutMethodType: (payload) =>
      axios.put(`${CONTROLLER_BASE_PATH}/merchant`, {
        ...payload,
      }),
    removeMerchantToPayoutMethodType: (payload) =>
      axios.delete(`${CONTROLLER_BASE_PATH}/merchant`, {
        data: { ...payload },
      }),
  };
};
