<template>
  <q-item exact :inset-level="insetLevel" :to="to" clickable v-ripple>
    <q-item-section avatar v-if="icon">
      <q-icon :name="icon" />
    </q-item-section>
    <q-item-section>{{ title }}</q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'd-list-item',
  props: {
    insetLevel: {
      type: Number,
      default: () => undefined,
    },
    title: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => undefined,
    },
    to: {
      type: [String, Object],
      default: () => undefined,
    },
  },
};
</script>

<style scoped></style>
