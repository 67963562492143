import { router } from '@/app/providers';
import { MESSAGES_401 } from '@/shared/constants';
import { authUtils, localStorageManager } from '@/shared/utils/index';
import Dialog from 'quasar/src/plugins/Dialog.js';;

const {
  storageKeys: { USER_DATA },
  actions: { removeItem },
} = localStorageManager;

const clearUserDataAndLogOut = async () => {
  authUtils.setAxiosToken('delete');
  removeItem(USER_DATA);
  await router.push({
    name: 'Login',
  });
};

export const unauthorizedHandler = async (error) => {
  if (error.response.status === 401) {
    const code = error.response.data?.message?.split('.').at(-1);
    if (code === MESSAGES_401.MISSING_OTP) {
      return Promise.reject({ message: MESSAGES_401.MISSING_OTP });
    } else if (code === MESSAGES_401.INVALID_OTP) {
      return Promise.reject({ message: MESSAGES_401.INVALID_OTP });
    } else if (code === MESSAGES_401.TIMEOUT) {
      return Promise.reject({ message: MESSAGES_401.TIMEOUT });
    } else if (
      error.response.data?.message?.includes(MESSAGES_401.ANOTHER_DEVICE)
    ) {
      Dialog.create({
        persistent: true,
        title: 'Warning!',
        html: true,
        message: error.response.data.message,
      }).onOk(clearUserDataAndLogOut);
    } else {
      await clearUserDataAndLogOut();
    }
  }
  return Promise.reject(error);
};
