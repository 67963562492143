import { ROLES } from '@/shared/constants';
import { ComputedPermissions } from '@/shared/utils/permissionsHelper';

export default {
  path: '/refill_account',
  name: 'Accounts',
  redirect: {
    name: 'AccountsList',
  },
  meta: {
    extraPermission(userData) {
      return ComputedPermissions.canShowRefillAccount(
        userData.data.permissions
      );
    },
    icon: 'mdi-account-multiple',
    roles: [
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_SUPERVISOR,
      ROLES.ROLE_AGENT,
      ROLES.ROLE_OPERATOR,
      ROLES.ROLE_SUPPORT_OFFICER,
    ],
  },
  component: {
    name: 'Accounts',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'AccountsList',
      meta: {
        title: 'List of accounts',
      },
      component: () => import('./list'),
    },
    {
      path: 'edit/:id',
      name: 'AccountEdit',
      meta: {
        icon: 'mdi-account-multiple',
        title: 'Edit account',
        excludeFromNavBar: true,
      },
      component: () => import('./edit'),
    },
  ],
};
