<template>
  <q-drawer v-bind="modelBySize" side="left" bordered @hide="onHide">
    <template v-slot:mini>
      <d-mini-list
        :value="value"
        @input="$emit('input', $event)"
        :items="menuItems"
      />
    </template>
    <d-common-list
      :value="value"
      @input="$emit('input', $event)"
      :items="menuItems"
    />
    <d-profile-article class="absolute-top" v-if="$q.screen.lt.md" />
  </q-drawer>
</template>

<script>
import { DCommonList, DMiniList, DProfileArticle } from './features';
import { mapGetters } from 'vuex';

export default {
  components: { DProfileArticle, DMiniList, DCommonList },
  name: 'd-navigation-drawer',
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    ...mapGetters({
      userData: 'GET_USER_DATA',
      userRoles: 'GET_USER_ROLES',
    }),

    menuItems: ({ $router, buildNavItems }) =>
      buildNavItems($router.options.routes),

    modelBySize() {
      return this.$q.screen.lt.md
        ? { value: this.value }
        : { mini: this.value, value: true };
    },
  },

  methods: {
    onHide() {
      if (this.$q.screen.lt.md) this.$emit('input', !this.value);
    },
    buildNavItems(routes) {
      return routes.filter((el) => {
        if (!el?.meta?.excludeFromNavBar) {
          if (el.children) el.children = this.buildNavItems(el.children);
          if (el?.meta?.extraPermission) {
            const result = el.meta.extraPermission(this.userData);
            return !!result;
          }
          if (el?.meta?.roles) {
            return !![...this.userRoles]
              .map((role) => el?.meta?.roles?.includes(role))
              .some((el) => el === true);
          }
          return true;
        } else return false;
      });
    },
  },
};
</script>
