import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_OPERATOR]: '/api/operator/payout_order',
  [ROLES.ROLE_AGENT]: '/api/agent/payout_order',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/payout_order',
  [ROLES.ROLE_ADMIN]: '/api/admin/payout_order',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/payout_order',
};

/**
 * @returns {{approveOrder: (function(*): Promise<AxiosResponse<any>>), getOrders: (function({filter?: {status: Number, merchantId: Number, payoutMethodTypeId: Number, merchantTransactionId: Number, operatorTransactionId: Number, currency: String, customerWalletId: Number, customerName: String}, limit?: {lastId: Number, maxResults: Number, descending: Boolean}}): Promise<AxiosResponse<*>>), declineOrder: (function(*): Promise<AxiosResponse<any>>)}}
 */
export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    /**
     * @description - Получение заказов на выпалаты
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {Number} payload.filter.status - статус
     * @param {Number} payload.filter.merchantId - ид мерчанта
     * @param {Number} payload.filter.payoutMethodTypeId
     * @param {Number} payload.filter.merchantTransactionId
     * @param {Number} payload.filter.operatorTransactionId
     * @param {String} payload.filter.currency
     * @param {Number} payload.filter.customerWalletId
     * @param {String} payload.filter.customerName
     * @param {Object} [payload.limit] - фильтры лимитов
     * @param {Number} payload.limit.lastId
     * @param {Number} payload.limit.maxResults
     * @param {Boolean} payload.limit.descending
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOrders: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/find`, {
        ...payload,
      }),
    declineOrder: ({ id, reasonDecline }) =>
      axios.put(`${CONTROLLER_BASE_PATH}/decline`, {
        id,
        reasonDecline,
      }),
    approveOrder: ({ id, operatorTransactionId }) =>
      axios.put(`${CONTROLLER_BASE_PATH}/approve`, {
        id,
        operatorTransactionId,
      }),

    /**
     * @description - Подтверждение отчета
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {Number} payload.filter.status - статус
     * @param {Number} payload.filter.merchantId - ид мерчанта
     * @param {Number} payload.filter.paymentRequisiteId
     * @param {Number} payload.filter.merchantTransactionId
     * @param {String} payload.filter.currency
     * @param {Number} payload.filter.customerTransactionId
     * @param {Number} payload.filter.customerWalletId
     * @param {String} payload.filter.customerName
     * @param {String} payload.email - почта
     * @returns {Promise<AxiosResponse<any>>}
     */
    applyReport: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/report`, {
        ...payload,
      }),
  };
};

export const changeAgentForPayouts = ({ ids, agent_id }) =>
  axios.put(`/api/ui/payout_order/agent_change`, {
    ids,
    agent_id,
  });

export const declineOrder = ({ ids, reason_decline }) =>
  axios.put(`/api/ui/payout_order/decline`, {
    ids,
    reason_decline,
  });
