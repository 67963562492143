import { DLogOutNotifyModal } from '@/features/logout-notify-modal';
import { LOGOUT_TIME_LIMIT, NOTIFICATION_TIME } from '@/shared/constants';
import { authUtils, localStorageManager } from '@/shared/utils';
import Dialog from 'quasar/src/plugins/Dialog.js';;

const { actions, storageKeys } = localStorageManager;

const logoutManager = () => {
  let timeoutId;
  let notificationId;

  function logout() {
    actions.removeItem(storageKeys.USER_DATA);
    location.reload();
  }

  function showNotification() {
    document.removeEventListener('mousemove', reset);
    document.removeEventListener('keydown', reset);
    Dialog.create({
      component: DLogOutNotifyModal,
    })
      .onOk(reset)
      .onCancel(logout);
  }

  function reset() {
    clearTimeout(timeoutId);
    clearTimeout(notificationId);

    if (!authUtils.isAuth()) {
      document.removeEventListener('mousemove', reset);
      document.removeEventListener('keydown', reset);
      return;
    }

    document.addEventListener('mousemove', reset);
    document.addEventListener('keydown', reset);

    timeoutId = setTimeout(logout, LOGOUT_TIME_LIMIT);
    notificationId = setTimeout(
      showNotification,
      LOGOUT_TIME_LIMIT - NOTIFICATION_TIME
    );
  }

  return reset;
};

export const resetTimeout = logoutManager();
