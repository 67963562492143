import { ROLES } from '@/shared/constants';

export default {
  path: '/merchants',
  name: 'Merchants',
  redirect: {
    name: 'MerchantsList',
  },
  meta: {
    icon: 'mdi-application',
    roles: [ROLES.ROLE_ADMIN],
  },
  component: {
    name: 'Merchants',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'MerchantsList',
      meta: {
        icon: 'mdi-application',
        title: 'Merchants list',
        roles: [ROLES.ROLE_ADMIN],
      },
      component: () => import('./list'),
    },
    {
      path: 'create',
      name: 'MerchantCreate',
      meta: {
        icon: 'mdi-view-dashboard',
        title: 'Merchants creation',
        roles: [ROLES.ROLE_ADMIN],
      },
      component: () => import('./edit'),
    },
    {
      path: 'edit/:id',
      name: 'MerchantEdit',
      meta: {
        excludeFromNavBar: true,
        title: 'Merchants editing',
        icon: 'mdi-application-edit',
        roles: [ROLES.ROLE_ADMIN],
      },
      component: () => import('./edit'),
    },
  ],
};
