import { authController } from '@/shared/api';
import { authUtils, localStorageManager } from '@/shared/utils';

const { actions, storageKeys } = localStorageManager;

export const module = {
  state: () => ({
    userData: null,
  }),
  getters: {
    GET_USER_DATA: (state) => state.userData,
    GET_USER_ROLES: (state) => state.userData?.data?.roles || [],
    GET_USER_PERMISSIONS: (state) => state.userData?.data?.permissions,
    GET_USER_CURRENCIES: (state) => {
      if (state.userData?.data?.currencies) {
        return state.userData.data.currencies;
      } else {
        return [];
      }
    },
  },
  mutations: {
    INIT_USER_DATA: (state) => {
      const userData = actions.getItem(storageKeys.USER_DATA);
      if (!userData) throw new Error('User data is not defined!');

      authUtils.setAxiosToken(userData?.token);
      state.userData = userData;
    },
    LOG_OUT: () => {
      actions.removeItem(storageKeys.USER_DATA);
    },
    LOG_IN: (state, payload) => {
      authUtils.setAxiosToken(payload.token);
      localStorageManager.actions.setItem({
        key: localStorageManager.storageKeys.USER_DATA,
        value: payload,
      });
      state.userData = payload;
    },
  },
  actions: {
    async LOG_OUT({ commit }) {
      commit('LOG_OUT');
    },
    async LOG_IN(ctx, { username, password, otp }) {
      const { data } = await authController.login({
        username,
        password,
        otp,
      });

      ctx.commit('LOG_IN', data);
    },
    async INIT_USER_DATA(ctx) {
      ctx.commit('INIT_USER_DATA');
    },
  },
};
