import { notify, unauthorizedHandler } from '@/shared/utils';
import axios from 'axios';

axios.interceptors.response.use(null, unauthorizedHandler);

axios.interceptors.response.use(null, (error) => {
  const message = error?.response?.data?.message;

  if (message) {
    notify.error(message);
  }

  return Promise.reject(error);
});

axios.defaults.timeout = 50000;

export { default as authController } from './auth.controller';
export { default as accountController } from './account.controller';
export { default as merchantController } from './merchant.controller';
export { default as usersController } from './users.controller';
export { default as refillMethodController } from './refillMethodType.controller';
export { default as payoutMethodController } from './payoutMethodType.controller';
export { default as refillOrderController } from './refillOrder.controller';
export { default as payoutOrderController } from './payoutOrder.controller';
export { default as paymentRequisitesController } from './paymentRequisites.controller';
export { default as ruleManagerController } from './ruleManager.controller';
export { default as currencyController } from './currency.controller';
export { default as prepaymentController } from './prepayment.controller';
export { default as balanceController } from './balance.controller';
export { default as payoutManagementController } from './payoutManagement.controller';
export { default as feeController } from './fee.controller';
