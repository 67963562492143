import _isObjectLike from 'lodash.isobjectlike';

// import _size from 'lodash.size';

function removeEmptyValuesRecursively(obj) {
  function isFalsy(val) {
    return !val?.toString().trim().length;
    // return true, if val is empty [], empty {} or null
    // return !_isObjectLike(val) ? val === null : _size(val) <= 0;
  }

  function removeEmptyValues(data) {
    if (_isObjectLike(data)) {
      let result = Object.entries(data).reduce(
        (res, [key, value]) => {
          const newValue = removeEmptyValues(value);

          // console.log(key, newValue, isFalsy(newValue));

          if (!isFalsy(newValue)) {
            res[key] = newValue === '' ? undefined : newValue;
          }

          return res;
        },
        Array.isArray(data) ? [] : {}
      );

      return Object.values(result).length ? result : undefined;
    }

    return isFalsy(data) ? undefined : data;
  }

  return removeEmptyValues(obj);
}

export default {
  removeEmptyValuesRecursively,
};
