<template>
  <d-list-item
    @click.native="$emit('click')"
    class="d-toggle-btn fixed-bottom"
    :icon="value ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'"
    :title="value ? '' : 'Collapse sidebar'"
  />
</template>

<script>
import DListItem from '@/shared/ui/list-item';

export default {
  components: { DListItem },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/quasar.variables.scss';

.d-toggle-btn {
  border-top: thin solid $separator-color;
}
</style>
