import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/prepayment',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/prepayment',
  [ROLES.ROLE_AGENT]: '/api/agent/prepayment',
};

export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    getPrepayments: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/find`, {
        ...payload,
      }),

    /**
     * @returns {Promise<AxiosResponse<any>>}
     * @param {Object} payload
     * @param {Number} payload.agentId,
     * @param {Number} payload.currencyId,
     * @param {String} payload.amount,
     * @param {String} payload.comment,
     */
    createPrepayment: (payload) => axios.post(CONTROLLER_BASE_PATH, payload),

    /**
     * @returns {Promise<AxiosResponse<any>>}
     * @param {Object} payload
     * @param {Number} payload.agentId,
     * @param {Number} payload.currencyId,
     * @param {String} payload.amount,
     * @param {String} payload.comment,
     */
    previewPrepayment: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/preview`, payload),

    /**
     * @description - Подтверждение отчета
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {String} payload.filter.status
     * @param {String} payload.filter.agentId
     * @param {String} payload.filter.type
     * @param {Object} payload.filter.createdAt
     * @param {String} payload.filter.createdAt.from
     * @param {String} payload.filter.createdAt.to
     * @param {String} payload.email - почта
     * @returns {Promise<AxiosResponse<any>>}
     */
    applyReport: (payload) =>
        axios.post(`${CONTROLLER_BASE_PATH}/report`, {
          ...payload,
        }),
  };
};
