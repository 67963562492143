import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/currency',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/currency',
  [ROLES.ROLE_OPERATOR]: '/api/operator/currency',
  [ROLES.ROLE_AGENT]: '/api/agent/currency',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/currency',
};

export default (role = ROLES.ROLE_ADMIN) => {
  const CONTROLLER_BASE_PATH = pathMap[role];
  return {
    getCurrency: () => axios.get(CONTROLLER_BASE_PATH),
    getCurrencyForPaymentRequisites: () =>
      axios.get(`${CONTROLLER_BASE_PATH}/currency_for_payment_requisites`),
  };
};
