import Notify from 'quasar/src/plugins/Notify.js';;

const success = (message = 'Success') => {
  Notify.create({
    type: 'positive',
    message,
    position: 'bottom-right',
    timeout: 1500,
    actions: [{ color: 'white', icon: 'mdi-close', fabMini: true, size: 'xs' }],
  });
};

const error = (message = 'Error', timeout = 3000) => {
  Notify.create({
    type: 'negative',
    message,
    position: 'bottom-right',
    timeout,
    actions: [{ color: 'white', icon: 'mdi-close', fabMini: true, size: 'xs' }],
  });
};

export default {
  success,
  error,
};
