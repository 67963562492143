import axios from 'axios';

const login = ({ username, password, otp }) =>
  axios.post('/api/login_check', {
    username,
    password,
    otp,
  });

export default {
  login,
};
