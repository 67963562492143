import {authUtils} from "@/shared/utils/index";
import {ROLES} from "@/shared/constants";

export const ComputedPermissions = {
    canShowRefillAccount: (permissions) => {
        return !!permissions?.allowRefillAccount
    },

    canShowRequisite: (permissions) => {
        const {
            allowCreateRequisites,
            allowEditRequisitePayload,
            allowEditRequisiteRefillMethod,
            allowEditRequisiteWeight,
            allowSwitchRequisite,
        } = permissions || {};

        const isAgent = authUtils.checkRoles([ROLES.ROLE_AGENT]);

        return isAgent || allowCreateRequisites || allowEditRequisitePayload || allowEditRequisiteRefillMethod || allowEditRequisiteWeight || allowSwitchRequisite;
    },

    canEditRequisite: (permissions) => {
        const {
            allowEditRequisitePayload,
            allowEditRequisiteRefillMethod,
            allowEditRequisiteWeight,
            allowSwitchRequisite,
        } = permissions || {};

        return allowEditRequisitePayload || allowEditRequisiteRefillMethod || allowEditRequisiteWeight || allowSwitchRequisite;
    }
};