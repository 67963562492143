import { routes } from '@/pages';
import { routerMiddlewares } from '@/shared/utils';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(routerMiddlewares.checkAccess);
router.beforeEach(routerMiddlewares.checkRoles);
router.afterEach(routerMiddlewares.setPageTitle);

export default router;
