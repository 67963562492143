import Dialog from 'quasar/src/plugins/Dialog.js';;

const confirmDialog = ({ title = 'Confirmation', message }) =>
  Dialog.create({
    title,
    message,
    cancel: true,
    persistent: true,
  });

export default {
  confirmDialog,
};
