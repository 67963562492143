import axios from 'axios';

const findRefillAccounts = async (payload) => {
  const { data } = await axios.post('/api/ui/refill_account/find', payload);

  data.sort((a, b) => b.id - a.id);

  return data;
};

const createRefillAccount = async (payload) => {
  const { data } = await axios.post('/api/ui/refill_account', payload);

  return data;
};

const updateRefillAccount = async (payload) => {
  const { data } = await axios.put('/api/ui/refill_account', payload);

  return data;
};

const updateRefillAccountCredentials = async (payload) => {
  const { data } = await axios.put(
    '/api/ui/refill_account/credentials',
    payload
  );

  return data;
};

const updateRefillAccountEnable = async (payload) => {
  const { data } = await axios.put('/api/ui/refill_account/enable', payload);

  return data;
};

const fetchRefillAccount = async ({ id, ...params }) => {
  const { data } = await axios.get(`/api/ui/refill_account/${id}`, {
    params,
  });

  data.requisites.sort((a, b) => b.id - a.id);

  return data;
};

const fetchRefillAccountMethods = async (params) => {
  const { data } = await axios.get('/api/ui/refill_account/methods', {
    params,
  });

  return data;
};

const fetchRefillAccountAgents = async (params) => {
  const { data } = await axios.get('/api/ui/refill_account/agents', {
    params,
  });

  return data;
};

const createRefillAccountRequisite = async (payload) => {
  const { data } = await axios.post(
    '/api/ui/refill_account/requisite',
    payload
  );

  return data;
};

const updateRefillAccountRequisite = async (payload) => {
  const { data } = await axios.put('/api/ui/refill_account/requisite', payload);

  return data;
};

const updateRefillAccountRequisiteEnable = async (payload) => {
  const { data } = await axios.put(
    '/api/ui/refill_account/requisite/enable',
    payload
  );

  return data;
};

const deleteRefillAccountRequisite = async (payload) => {
  const { data } = await axios.delete('/api/ui/refill_account/requisite', {
    data: payload,
  });

  return data;
};

export default {
  findRefillAccounts,
  createRefillAccount,
  updateRefillAccount,
  updateRefillAccountCredentials,
  updateRefillAccountEnable,
  fetchRefillAccount,
  fetchRefillAccountMethods,
  fetchRefillAccountAgents,
  createRefillAccountRequisite,
  updateRefillAccountRequisiteEnable,
  updateRefillAccountRequisite,
  deleteRefillAccountRequisite,
};
