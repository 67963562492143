<template>
  <q-layout view="hHh Lpr lff">
    <q-header elevated class="bg-primary text-white">
      <d-toolbar @toggle="toggleDrawer" />
    </q-header>

    <d-navigation-drawer v-model="drawer" />

    <q-page-container>
      <router-view :key="$route.path" />
    </q-page-container>
  </q-layout>
</template>
<script>
import DNavigationDrawer from '@/features/navigation-drawer';
import { DToolbar } from '@/features/toolbar';
import { resetTimeout } from '@/shared/utils/logout';

export default {
  components: { DNavigationDrawer, DToolbar },
  data: () => ({
    drawer: true,
  }),
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  mounted() {
    resetTimeout();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .q-toolbar {
  min-height: 40px;
}
</style>
