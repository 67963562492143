<template>
  <q-dialog ref="dialog" @hide="onDialogHide" persistent>
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="row justify-end">
          <q-btn flat fab-mini icon="mdi-close" @click="onOKClick" />
        </div>
      </q-card-section>
      <q-card-section>
        <div class="row justify-center">
          <q-circular-progress
            show-value
            class="text-white q-ma-md"
            :value="progress"
            size="90px"
            :thickness="0.2"
            color="primary"
            center-color="grey-8"
            track-color="transparent"
          >
            {{ time }}
          </q-circular-progress>
        </div>
        <div class="row justify-center">
          <div>Your session will expire in 2min!</div>
          <div class="text-caption">
            For security purporses we will log you out!
          </div>
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="Logout" outline @click="onCancelClick" />
        <q-btn color="primary" label="Continue" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { NOTIFICATION_TIME } from '@/shared/constants';
import { date } from 'quasar';

export default {
  data: () => ({
    interval: null,
    progress: 100,
    deadline: NOTIFICATION_TIME + Date.now(),
    time: date.formatDate(NOTIFICATION_TIME, 'mm:ss'),
  }),

  mounted() {
    this.interval = setInterval(() => {
      let distance = this.deadline - Date.now();
      this.time = date.formatDate(distance, 'mm:ss');

      this.progress = (distance * 100) / NOTIFICATION_TIME;
      if (distance <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit('hide');
    },

    onOKClick() {
      this.$emit('ok');
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
