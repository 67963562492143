import { authUtils } from './index';
import localStorageManager from './localStorageManager';
import { ROLES } from '@/shared/constants';
import axios from 'axios';

const { storageKeys, actions } = localStorageManager;

const setAxiosToken = (token, source = axios.defaults.headers.common) => {
  if (!token) return;
  if (token === 'delete') delete source['Authorization'];
  source['Authorization'] = `Bearer ${token}`;
};

const isAuth = () => !!actions.getItem(storageKeys.USER_DATA)?.token;

const checkRoles = (rolesToCheck = [], guardRoles) => {
  const {
    actions: { getItem },
    storageKeys: { USER_DATA },
  } = localStorageManager;

  const roles = guardRoles || getItem(USER_DATA)?.data?.roles;

  return roles
    ?.map((role) => rolesToCheck.includes(role))
    .some((el) => el === true);
};

const getRoleByHierarchy = () => {
  const check = authUtils.checkRoles;

  if (check(ROLES.ROLE_ADMIN)) {
    return ROLES.ROLE_ADMIN;
  } else if (check(ROLES.ROLE_ADMIN_FILTER)) {
    return ROLES.ROLE_ADMIN_FILTER;
  } else if (check(ROLES.ROLE_SUPERVISOR)) {
    return ROLES.ROLE_SUPERVISOR;
  } else if (check(ROLES.ROLE_AGENT)) {
    return ROLES.ROLE_AGENT;
  } else if (check(ROLES.ROLE_OPERATOR)) {
    return ROLES.ROLE_OPERATOR;
  } else {
    return ROLES.ROLE_SUPPORT_OFFICER;
  }
};

export default {
  getRoleByHierarchy,
  setAxiosToken,
  isAuth,
  checkRoles,
};
