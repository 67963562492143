<template>
  <q-toolbar>
    <q-btn
      v-if="$q.screen.lt.md"
      dense
      flat
      round
      icon="mdi-menu"
      @click="$emit('toggle')"
    />

    <q-toolbar-title>
      <router-link to="/" class="q-link">
        <span class="text-uppercase text-white">{{ $appName }}</span>
      </router-link>
    </q-toolbar-title>
    <d-profile-chip v-if="$q.screen.gt.sm" is-with-menu is-right />
  </q-toolbar>
</template>

<script>
import { DProfileChip } from '@/features/profile-chip';

export default {
  components: { DProfileChip },
  data: () => ({
    drawer: true,
  }),
};
</script>
