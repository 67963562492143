<template>
  <q-scroll-area
    :class="$q.screen.gt.sm && 'fit'"
    :style="$q.screen.lt.md && 'height: calc(100% - 170px); margin-top: 170px;'"
  >
    <q-list>
      <template v-for="route in items">
        <component
          :is="getListItem(route).is"
          :key="route.name"
          v-bind="{ ...getListItem(route).binding }"
        >
          <template v-for="childRoute in route.children">
            <component
              :is="getListItem(childRoute).is"
              :key="childRoute.name"
              v-bind="{ ...getListItem(childRoute, true).binding }"
            />
          </template>
        </component>
      </template>
      <d-toggle-btn
        v-if="$q.screen.gt.sm"
        @click="$emit('input', !value)"
        :value="value"
        :style="{ backgroundColor: 'white' }"
      />
      <q-btn
        v-else
        color="primary"
        label="Logout"
        class="fixed-bottom q-ma-sm"
        @click.stop="logout"
      />
    </q-list>
  </q-scroll-area>
</template>

<script>
import DToggleBtn from '@/features/navigation-drawer/features/toggle-btn.vue';
import withLogOut from '@/shared/mixins/withLogOut';
import DListItem from '@/shared/ui/list-item';

export default {
  components: { DListItem, DToggleBtn },
  mixins: [withLogOut],
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getListItem(route, isNested = false) {
      if (route.children) {
        return {
          is: 'q-expansion-item',
          binding: {
            icon: route?.meta?.icon,
            'expand-separator': true,
            label: route.name,
            'header-inset-level': isNested ? 1 : undefined,
            to: { name: route.name },
          },
        };
      } else {
        return {
          is: DListItem,
          binding: {
            icon: route?.meta?.icon,
            title: route.meta?.title,
            'inset-level': isNested ? 1 : undefined,
            to: { name: route.name },
          },
        };
      }
    },
  },
};
</script>
