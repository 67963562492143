import App from './index.vue';
import quasarOptions from './plugins/quasar';
import { router, store } from './providers';
import CommonLayout from '@/layouts/common';
import MinimalLayout from '@/layouts/minimal';
import '@/registerServiceWorker';
import { APP_NAME } from '@/shared/constants';
import '@/styles/index.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import Quasar from 'quasar';
import Vue from 'vue';
import Fragment from 'vue-fragment';

dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);

Vue.config.productionTip = false;

Vue.use(Quasar, quasarOptions);
Vue.use(Fragment.Plugin);

Vue.component('common-layout', CommonLayout);
Vue.component('minimal-layout', MinimalLayout);
Vue.prototype.$appName = APP_NAME;

export const vueApp = new Vue({
  router,
  store,
  beforeCreate() {
    store.dispatch('INIT_USER_DATA').catch(console.warn);
  },
  render: (h) => h(App),
});
