import { ROLES } from '@/shared/constants';

export default {
  path: '/payout-methods',
  name: 'Payout methods',
  redirect: {
    name: 'PayoutMethodsList',
  },
  meta: {
    icon: 'mdi-cash-multiple',
    roles: [ROLES.ROLE_ADMIN],
  },
  component: {
    name: 'PayoutMethods',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'PayoutMethodsList',
      meta: {
        icon: 'mdi-cash-multiple',
        title: 'Payout Methods list',
        roles: [ROLES.ROLE_ADMIN],
      },
      component: () => import('./list'),
    },
    {
      path: 'create',
      name: 'PayoutMethodsCreate',
      meta: {
        icon: 'mdi-cash',
        title: 'Payout Methods creation',
        roles: [ROLES.ROLE_ADMIN],
      },
      component: () => import('./edit'),
    },
    {
      path: 'edit/:id',
      name: 'PayoutMethodsEdit',
      meta: {
        title: 'Payout Methods editing',
        excludeFromNavBar: true,
        roles: [ROLES.ROLE_ADMIN],
      },
      component: () => import('./edit'),
    },
  ],
};
