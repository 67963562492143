import { ROLES } from '@/shared/constants';

const roles = [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR];

export default {
  path: '/payout-assignment-rules',
  name: 'Payout assignment',
  redirect: {
    name: 'PayoutAssignment',
  },
  meta: {
    icon: 'mdi-cog-outline',
    roles,
  },
  component: {
    name: 'RulesManager',
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: 'list',
      name: 'PayoutAssignment',
      meta: {
        icon: 'mdi-application',
        title: 'Rules list',
        roles,
      },
      component: () => import('./list'),
    },
    {
      path: 'create',
      name: 'PayoutAssignmentCreation',
      meta: {
        icon: 'mdi-application',
        title: 'Rule creation',
        roles,
      },
      component: () => import('./edit'),
    },
    {
      path: 'edit/:id',
      name: 'PayoutAssignmentEdit',
      meta: {
        excludeFromNavBar: true,
        icon: 'mdi-application',
        roles,
      },
      component: () => import('./edit'),
    },
  ],
};
