export default {
  path: '/login',
  name: 'Login',
  component: () => import('./index.vue'),
  meta: {
    excludeFromNavBar: true,
    layout: 'minimal',
    title: 'Login',
    guest: true,
  },
};
