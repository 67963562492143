import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/payment_requisite',
  [ROLES.ROLE_OPERATOR]: '/api/operator/payment_requisite',
  [ROLES.ROLE_AGENT]: '/api/agent/payment_requisite',
  [ROLES.ROLE_ADMIN_FILTER]: '/api/admin_filter/payment_requisite',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/payment_requisite',
  [ROLES.ROLE_SUPPORT_OFFICER]: '/api/support_officer/payment_requisite',
};

export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    getPaymentRequisites: () => axios.get(CONTROLLER_BASE_PATH),

    /**
     * @description - Получение платежных реквизитов
     * @param {Object} payload
     * @param {Object} [payload.filter] - фильтры
     * @param {Number} payload.filter.userId
     * @param {Number} payload.filter.refillMethodTypeId
     * @param {String} payload.filter.payload
     * @param {String} payload.filter.name
     * @param {Object} [payload.limit] - фильтры лимитов
     * @param {Number} payload.limit.lastId
     * @param {Number} payload.limit.maxResults
     * @param {Boolean} payload.limit.descending
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPaymentRequisitesWithFilters: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/find`, {
        ...payload,
      }),

    getPaymentRequisiteById: (id) => axios.get(`${CONTROLLER_BASE_PATH}/${id}`),

    removePaymentRequisite: (id) =>
      axios.delete(CONTROLLER_BASE_PATH, {
        data: { id },
      }),

    /**
     * @description - Обновление платежного метода
     * @param {Object} payload
     * @param {Number} payload.id
     * @param {Boolean} payload.active
     * @param {Number} payload.weight
     * @param {String} payload.name
     * @param {Object} payload.payload
     * @param {Array} payload.refillMethodTypes
     * @param {Object} payload.refillMethodTypes[0]
     * @param {Number} payload.refillMethodTypes[0].id
     * @param {Number} payload.user
     * @param {Number} payload.user.id
     * @returns {Promise<AxiosResponse<any>>}
     */
    updatePaymentRequisite: (payload) =>
      axios.put(CONTROLLER_BASE_PATH, {
        ...payload,
      }),

    /**
     * @description - Обновление платежного метода
     * @param {Object} payload
     * @param {Number} payload.id
     * @param {Boolean} payload.active
     * @param {Number} payload.weight
     * @param {String} payload.name
     * @param {Object} payload.payload
     * @param {Object} payload.refillMethodType
     * @param {Number} payload.refillMethodType.id
     * @param {Number} payload.user
     * @param {Number} payload.user.id
     * @returns {Promise<AxiosResponse<any>>}
     */
    createPaymentRequisite: (payload) =>
      axios.post(CONTROLLER_BASE_PATH, {
        ...payload,
      }),

    /**
     * @description - Создание фильтра для платежного реквизита
     * @param {String} id - requisite ID
     * @param {Object} payload
     * @param {Boolean} payload.active - статус
     * @param {String} payload.name -  наименование
     * @param {Object} payload.payload -  любой JS объект
     * @returns {Promise<AxiosResponse<any>>}
     */
    createPaymentRequisiteFilter: ({ id, payload }) =>
      axios.post(`${pathMap[ROLES.ROLE_ADMIN_FILTER]}/${id}/filter`, {
        ...payload,
      }),

    /**
     * @description - Обновление фильтра для платежного реквизита
     * @param {String} id - requisite ID
     * @param {Object} payload
     * @param {Boolean} payload.active - статус
     * @param {String} payload.name -  наименование
     * @param {Object} payload.payload -  любой JS объект
     * @returns {Promise<AxiosResponse<any>>}
     */
    updatePaymentRequisiteFilter: ({ id, payload }) =>
      axios.put(`${pathMap[ROLES.ROLE_ADMIN_FILTER]}/${id}/filter`, {
        ...payload,
      }),

    /**
     * @description - Получние фильтра для платежного реквизита
     * @param {String} id - requisite ID
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPaymentRequisiteFilter: (id) =>
      axios.get(`${pathMap[ROLES.ROLE_ADMIN_FILTER]}/${id}/filter`),

    /**
     * @description - Удаление фильтра для платежного реквизита
     * @param {String} id - requisite ID
     * @returns {Promise<AxiosResponse<any>>}
     */
    removePaymentRequisiteFilter: (id) =>
      axios.delete(`${pathMap[ROLES.ROLE_ADMIN_FILTER]}/${id}/filter`),
  };
};
