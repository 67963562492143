<template>
  <q-scroll-area class="fit mini-slot cursor-pointer">
    <div class="column">
      <template v-for="route in links">
        <q-btn class="q-py-sm" flat :key="route.name" v-bind="{ ...route }">
          <q-tooltip
            anchor="center right"
            self="center left"
            :offset="[10, 10]"
          >
            {{ route.name }}
          </q-tooltip>
        </q-btn>
      </template>
    </div>
    <d-toggle-btn
      @click="$emit('input', !value)"
      :value="value"
      :style="{ backgroundColor: 'white' }"
    />
  </q-scroll-area>
</template>

<script>
import DToggleBtn from './toggle-btn';

export default {
  components: { DToggleBtn },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    links: ({ items, $route }) =>
      items.map((el) => ({
        name: el.name,
        to: { name: el.name },
        icon: el.meta?.icon,
        'text-color': $route.path.includes(el.path) ? 'primary' : undefined,
      })),
  },
};
</script>

<style scoped></style>
